<template>
  <ion-page>
    <ion-content>
      <ion-accordion-group v-if="index === 1">
        <ion-accordion value="first">
          <ion-item slot="header" class="ion-label">
            <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-1.png" slot="start"/>
            <ion-label>医疗福利</ion-label>
            <ion-icon :icon="chevronDownOutline" class="ion-accordion-toggle-icon" slot="end"></ion-icon>
          </ion-item>
          <div class="ion-padding content" slot="content">
            澳大利亚拥有全球最完善的国民保健系统，澳洲政府通过Medicare（国民保健）向国民提供一系列医疗服务帮助。
            <img src="https://static.yuzhangmen.cn/custom/aboutau/content-1.webp"/>
            <h5>Medicare</h5>
            <p>澳大利亚政府通过Medicare（国民保健）向国民提供医疗、验光和医院费用方面的帮助。国民保健（Medicare）为在公立医院就医的病人提供免费治疗，还包括验光师和牙医提供的一些有限服务。</p>
            <p>澳大利亚政府还通过药品补贴计划（PBS）为大部份药物的费用提供补贴。Medicare
              不支付救护车费用、大多数牙科服务、理疗、眼镜、足医服务、脊椎指压按摩服务或私立医院的住院费用。新移民需要携带护照及永居签证到Medicare
              办事处申请，如果符合所有资格要求，大约在三周后会收到Medicare的正式卡，在此之前可以使用临时号码。在许多情况下，都需要先支付医疗费，然后再从Medicare获得部份退款。</p>
            <p>Medicare
              负责管理澳大利亚儿童免疫登记册。如果你有未满七岁的孩子，请务必将孩子的免疫记录带来，以帮助澳大利亚的医生确定孩子的免疫是否现时有效。在给孩子报读学校时，孩子的免疫记录可以帮助您达到免疫要求，而且一些家庭补助金也有此项要求。</p>
            <p>此外，Medicare 还通过家庭补助金和服务来提供帮助。当地Medicare 办事处可以发放一些付款，其中包括家庭税补助金（Family Tax Benefit）、婴儿津贴（Baby
              Bonus）、托儿福利金（Child Care Benefit）和婴儿免疫津贴（Maternity Immunisation Allowance）</p>
            <h5>Centre link 保健卡</h5>
            <p>对于符合政府规定的“低收入”资格的家庭，可以申请获得政府的保健卡（Health Care Card）。 该卡令你可以获得多种优惠，包括药物及医疗服务（医生、牙医及救护车）。</p>
            <p>注：即使你有保健卡，在获得所有基本医院和医学治疗时，仍需与保健卡一起出示Medicare 卡。</p>
            <h5>私人医疗保险</h5>
            <p>
              为了缓解公立医疗压力，澳大利亚政府鼓励国民购买私人医疗保险，并提供经济上的补助，补助额最高达到40%。私人保险持有者可以私家病人的身份在私立或公立医院接受治疗，支付全部或部份治疗费用，幷可支付不在Medicare
              范围内的服务，如大多数牙科服务、大多数视光服务以及一些州和领地的救护车运送。</p>
            <h5>社区健康中心</h5>
            <p>社区健康中心无论年龄，为所属居民提供低收费的医疗服务。通常提供的服务包括护理、健康教育与宣传、物理治疗、牙科服务、医护、心理辅导与社会福利，为有幼儿的家庭提供的健康服务。</p>
          </div>
        </ion-accordion>
        <ion-accordion value="second">
          <ion-item slot="header" class="ion-label">
            <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-2.png" slot="start"/>
            <ion-label>养老福利</ion-label>
            <ion-icon :icon="chevronDownOutline" class="ion-accordion-toggle-icon" slot="end"></ion-icon>
          </ion-item>
          <div class="ion-padding content" slot="content">
            <img src="https://static.yuzhangmen.cn/custom/aboutau/conent-2.jpeg"/>
            澳洲养老体系主要由三部分构成
            <ol style="margin-left: -1em">
              <li>
                <h5>雇主支付的养老金 （Superanuation）</h5>
                澳洲养老金Superannuation 是澳洲法律规定的，由雇主为18岁以上的并且税前月收入不低于450澳元的员工缴纳的养老金。这笔养老金被称为 （Super Guarantee
                Charge），雇主每年为员工支付的养老金不得低于员工税前年薪的9.5%。
                此外，法律也规定这笔养老金必须在个人到达法定退休年龄之后，或达到其他硬性指标例如：伤残，财务危机，离境，定居等才能分批或者一次性取出。
              </li>
              <li>
                <h5>政府支付的养老金 （Age Pension）</h5>
                养老每年花费澳洲政府超过420亿元。只要年满65岁，连续在澳洲居住10年，并且收入和资产低于一定限额，那么就可以领取政府发给退休人员的老年金。
              </li>
              <li>
                <h5>个人缴纳的养老金（Super Contribution）</h5>
                个人缴纳养老金，以自愿为原则个人额外对自己的养老金额外供款 (self contribution). 澳洲政府也通过设置鼓励性的税务政策优惠来鼓励个人多向养老金交纳供款。
                在社会福利方面澳洲政府也为退休老人提供极大的福利<br>
                <ul style="margin-left: -1em">
                  <li>看病：免费，还包吃包住</li>
                  <li>药：不管处方药，还是非处方药统统只要4.7澳元</li>
                  <li>政府提供的社区服务（垃圾处理费等），退休之后会降为每年480澳元</li>
                  <li>水，电,，煤气各个州政府也都有不同程度的补贴</li>
                  <li>公共交通：一天两块五 火车 公车 轮渡 随便坐 ！</li>
                  <li>所有收费公园也对老人免费开放的</li>
                  <li>每年还有四次免费的省内旅游</li>
                  <li>超过60岁的澳洲居民，并且有过在某个连续的12个月的时期内，每周做带薪工作超过20小时的历史，就可以向所在州政府申请 Seniors Card（老年人卡）。 持有Seniors
                    Card（老年人卡）可以在新南威尔士州超过5700家的商家获得优惠。
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </ion-accordion>
        <ion-accordion value="3rd">
          <ion-item slot="header" class="ion-label">
            <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-3.png" slot="start"/>
            <ion-label>生育福利</ion-label>
            <ion-icon :icon="chevronDownOutline" class="ion-accordion-toggle-icon" slot="end"></ion-icon>
          </ion-item>
          <div class="ion-padding content" slot="content">
            <img src="https://static.yuzhangmen.cn/custom/aboutau/content-3.webp"/>
            <h5>1、带薪产假</h5>
            <p>澳大利亚政府目前实施每次最多18周的带薪产假政策，这段时间内产妇可以休假并目获得18周的工资。
              按照澳大利亚政府的规定，带薪产假和育儿补贴是不能同时领取的。</p>
            <h5>2、家庭税收福利相关政策</h5>
            <p>联邦政府的家庭税收福利政策，是一个由两个部分来组成的政策，能够帮助减少家庭养育孩子的成本，其中分为家庭税收福利A部分以及B部分。
              家庭税收福利A部分对每一个孩子来说都适用，而支付的抚恤金的金额就和每个家庭的具体情况相关。
              家庭税收福利A对干那些需要额外资助的单亲父母带儿家庭，或者只有一份收入的家庭不适用，因此政府还有家庭税收福利B类别的补贴。</p>
            <h5>新生婴儿补贴</h5>
            <p>2014年3月1日以后出生的孩子，你可以申请新生婴儿补贴。</p>
            4、父母补贴<br>
            <p>澳大利亚政府对单身父母照顾8岁以下孩童者，或者有伴侣，共同照顾6岁以下孩童者，满足资产和收入测试的，可以发放父母补贴。父母补贴是支付给孩子的主要看护人的，父母双方只有一方能够领取该补贴。</p>
            5、父亲和伴侣补贴<br>
            <p>为了能够支持父亲和伴侣对干新生婴儿或者领养婴儿的照顾，政府为他们支付最多2周的补贴。</p>
            6、托儿费用补助<br>
            <p>澳大利亚政府向养育孩子的父母们提供托儿费用补助。除了其它育儿福利和援助资金以外，澳大利亚政府向使用政府审批托儿服务的澳人提供50%的托儿费用补助。</p>
            7、托儿福利金<br>
            <p>澳大利亚政府为了帮助那些使用政府审批或者登记的托儿机构服务的澳人更好地负担，推出托儿福利金政策。根据澳人家庭情况的不同，政府所发放的托儿福利金也有所不同，申请人必须满足收入测试。
              澳大利亚政府规定，无论孩子是亲生的，或者是领养的都会获得基本的澳洲生育福利。</p>
          </div>
        </ion-accordion>
        <ion-accordion value="4st">
          <ion-item slot="header" class="ion-label">
            <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-4.png" slot="start"/>
            <ion-label>失业福利</ion-label>
            <ion-icon :icon="chevronDownOutline" class="ion-accordion-toggle-icon" slot="end"></ion-icon>
          </ion-item>
          <div class="ion-padding content" slot="content">
            <img src="https://static.yuzhangmen.cn/custom/aboutau/content-4.webp"/>
            <h5>失业救济金</h5>
            <p>
              每双周约$330。凡在工作年龄内，有工作能力，愿意工作而找不到工作者可以领取。但新移民要等两年才有资格申领。另外，政府还有“寻找工作补贴”和“重新开始津贴”计划，这些计划使失业者参加或重新参加就业得到鼓励和支持。同时，失业者遇到比较合适的工作岗位时，失业者是不能拒绝的。</p>
            <h5>特别救济金</h5>
            <p>发放给生活有困难的人，新移民在其无法控制的情况下而导致的状况改变时才可申领，每双周约$330。</p>
            <h5>工伤收入补偿金</h5>
            <p>收入补偿的目的是当受伤劳动者不能工作或者不适合工作的情况下，对于其工资收入损失作替代性的支付。收入补偿金额基于受伤前劳动者的工资收入和劳动能力的标准进行确定</p>
            <h5>租房补贴</h5>
            <p>如果你是租住房，且够资格享受FTB,就可以得到租房补贴。</p>
            <h5>疾病和伤残津贴</h5>
            <p>因伤残领取抚恤金或因病而领取临时补贴的人，同时可以接受康复治疗和培训，以便观察他们是否能重返工作岗位。父母或监护人因孩子身体、智力或心理伤残而需要在家得到高水平护理和照顾的，也可以领取护理补贴。</p>
          </div>
        </ion-accordion>
      </ion-accordion-group>
      <template v-if="index === 2">
        <ion-item style="--border-width: 0;border: 0" class="ion-label" lines="none">
          <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-8.png" slot="start"/>
          <ion-label>人口民族</ion-label>
        </ion-item>
        <div class="ion-padding content">
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-1.jpeg"/>
          <p>澳大利亚人是澳大利亚联邦居民的总称，包括英裔澳大利亚人和澳大利亚原住民等，是澳大利亚的主体民族。通用英语，信基督教新教和天主教，多住城镇，主要从事工矿业和饲养业。</p>
          <p>截止2021年3月，澳大利亚联邦人口为2569万（2020年9月）。74%为英国及爱尔兰裔，亚裔占5%，土著人占2.7%，其他民族占18.3%。</p>
          <p>澳大利亚与美国一样是外来移民人口比例占绝多数的国家，其外来移民数量更居世界第一。例如欧洲的德国、希腊、意大利和一些亚洲国家或地区，如香港、越南、台湾、日本、中国大陆、印度和西亚诸国。</p>
          <p>近年来澳大利亚东岸的一些国际化大都市，如悉尼和墨尔本以其高福利、高薪水以及良好的经济氛围吸引了包括美国在内的很多北美以及南美的新移民。中国和印度近年来超过英国等欧洲国家成为澳大利亚单年度最大移民的来源国。</p>
        </div>
      </template>
      <template v-if="index === 3">
        <ion-item style="--border-width: 0;border: 0" class="ion-label" lines="none">
          <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-5.png" slot="start"/>
          <ion-label>地理环境</ion-label>
        </ion-item>
        <div class="ion-padding content">
          <p>
            澳大利亚又叫澳洲，是一个位于南半球的国家。它还是世界上唯一一个国土覆盖一整个大陆的国家，其绝大部分地区都位于澳大利亚大陆，部分地区位于一些岛屿。澳大利亚位于南太平洋和印度洋之间，四面都环海，北部与东南亚接近，和非洲、南美洲以及南极洲隔海相望。</p>
          <p>澳大利亚的地形独具特色。它的东部是山地，中部是平原，而西部是高原，整体呈现出中间低、两边高的特征。全国最高峰科修斯科山海拔2228米，位于澳大利亚东南部，首都堪培拉附近。</p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-2.jpeg"/>
          <p>
            澳大利亚跨越两个气候带，北部属于热带，南部属于温带。由于赤道穿过国土中部，所以中部大部分地区为热带沙漠气候。它的整体气候呈现干燥炎热的特征，呈半环状分布，全国约有70％的国土属于干旱或半干旱地带，中部有20％的沙漠无人区。</p>
          <p>由于大陆地势平坦且气候干旱，澳大利亚并没有形成较大的河流。墨累河和达令河是澳大利亚最长的两条河流，但因为平坦的地势和干旱的气候影响，河流大多数时候处于断流的状态。</p>
          <p>澳大利亚地广人稀，人口主要分布在东南沿海地区和其他沿海的湿润地带。澳大利亚拥有丰富的矿产资源，有“坐在矿车上的国家”之称，它的铝土矿储量占世界总储量的35％，居世界首位。此外，它的石油和天然气资源也很丰富。</p>
          <h4>空气</h4>
          <p>澳大利亚的空气好到可以用一句话形容——天然氧吧永远都在15分钟车程内。根据美国耶鲁大学的2018年版的全球环境绩效指数EPI报告，澳大利亚的空气质量排名全球前列。
            有些无聊的商人还因此把澳大利亚的空气瓶装起来，卖给深受雾霾困扰的中国人，一瓶售价约14美元。 下面这张图，颜色越深代表空气越好。</p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-3.jpeg"/>
          <h4>动物</h4>
          <p>
            澳大利亚最多的生物不是人，而是袋鼠。这里是一块人与动物和谐相处的神奇大陆。很多人眼中，澳大利亚不仅仅是个大农村，还是个巨大的野生动物园，像袋鼠、考拉、鸭嘴兽、鸸鹋、袋熊、针鼹，都是澳大利亚独有的，另外，还有200多种哺乳动物、800多种鸟、300多种蜥蜴、140多种蛇和两类鳄鱼。
            澳大利亚的动物给人的感觉是，都有点傻乎乎的，见到人也不知道躲，蠢萌蠢萌的。</p>
          <h4>绿化</h4>
          <p>澳大利亚是一个很绿的地方。
            首都堪培拉就是一个70%面积都是绿化的城市。澳大利亚各大城市的平均树木覆盖率是39%。只有8%是由“光秃表面”组成的，包括各种建筑，人行道，停车场和水体。其余则是由灌木区和被清空等待建设的工地，草场和草地组成。
            澳大利亚的人均寿命为82.5岁，这与和谐环保的自然环境有着密不可分的关系。光是空气一项，就能让你多活好几年。生活在澳大利亚这个一半城市一半农村的大陆，感受慢生活带给你的悠闲，正是吸引每年几十万人申请移民到这里的原因。</p>
        </div>
      </template>
      <template v-if="index === 4">
        <ion-item style="--border-width: 0;border: 0" class="ion-label" lines="none">
          <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-6.png" slot="start"/>
          <ion-label>教育就业</ion-label>
        </ion-item>
        <div class="ion-padding content">
          <h4>教育体系</h4>
          <p>为孩子移民必看：澳大利亚的教育究竟怎样？
            经过200年的努力，中国的王姓和张姓都已经成为澳洲大户了，占到总人口的5%，并且近些年增长的速度很快。大多数中国人移民之后，关心的第一个问题就是孩子的教育，相比于闻名世界的英美教育，澳大利亚的教育水平、费用究竟如何呢？</p>
          <h4>总览</h4>
          <p>因为澳大利亚人对教育非常重视，政府每年投入大量资金用于教育和学生补助，使得民众受教育的普及率非常高，拥有大学学历的人占到总人口的56%。
            澳大利亚沿袭英国式教育，特点是开放、活跃，注重思维训练，除了这些，澳大利亚还很看重教育的实用性，这也是他们的职业教育比较发达的原因。培养孩子可以谋生的一技之长，而不是一味的灌输无用的思想。
            澳大利亚的大学有6所排名全球前100名，相比其他教育大国，澳大利亚的大学申请难度更为宽松，但这不意味着只要考上就高枕无忧了，他们实行的是宽进严出，想要拿到毕业证，比“高考”的难度大很多。
            整个澳大利亚的教育体系分为四种： 1、幼儿教育； 2、中小学教育； 3、职业教育； 4、大学教育；</p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-4.jpeg"/>
          <h4>幼儿教育</h4>
          <p>澳大利亚的幼儿教育不属于义务教育的范畴，公立和私立的各占一半，收费也比较昂贵，平均成本为一小时6澳元。尽管政府又育儿补贴，但这也是相当大的一笔费用。
            澳大利亚的幼儿园是按小时和天来收费的，因为在澳大利亚人的观念里，幼儿时期是人格和各种基本能力的萌芽时期，更需要父母的引导和陪伴，所以，在澳大利亚不一定要上幼儿园，就算是上了，也只是玩游戏和托管，没有实质的知识教学，更不会有家庭作业，每个家庭可以根据自己的实际情况选择上或不上，上的话也可以自由选择去幼儿园的天数。</p>
          <h4>中小学教育</h4>
          <p>澳大利亚实行的是12-13年义务教育，根据各州不同的教育情况而定。如果就读的是公立学校，是免费的，而且政府还会在文具、教材上进行补贴，平均每个孩子一年补贴400多澳元。如果就读的是教会学校或私立学校，则需要收费。
            澳大利亚中小学的教育质量在全世界位居前列。如经合作组织国际学生评估项目(PISA)，在2009年和2012年的两次测试中，澳大利亚的中小学生在阅读、科学和数学等特定科目中的水平都超过英美，名列前茅。</p>
          <h4>职业教育</h4>
          <p>澳大利亚的职业教育，是以满足产业发展和培养专业技术人才为导向的，确保所用即所学。在这方面，已获得世界经济合作与发展组织、联合国教科文组织以及国际劳工组织的高度认可。
            在全世界范围来看，职业教育德国排名第一，澳大利亚排名第二，而且澳大利亚蓝领稀缺，在澳大利亚接受职业教育后很容易找到工作并且拿到高薪，更享有优先移民的权利。
            目前澳大利亚最大的职业教育学院是启思蒙学院，位于维多利亚州的墨尔本市。学院拥有6个校区，下设16个系，100多个专业，几百门课程，具有颁发大专、本科和硕士研究生文凭的资格，该院全日制在校学生接近90000人。
            职业教育学院学费一的般费用在8000-10000澳币/年左右。</p>
          <h4>大学教育</h4>
          <p>澳大利亚的大学教育质量排名世界前列。世界最权威的大学排名QS，2018年的榜单上，共有37所澳大利亚大学上榜。要知道，澳大利亚一共也就39所大学，上榜率这么高，足以证明整体的教育质量。
            最著名的8所大学组成了澳洲八大名校（Group of Eight；Go8），学术地位相当于美国的常春藤盟校： 澳洲国立大学（堪培拉） 蒙纳许大学（墨尔本） 新南威尔士大学（悉尼） 悉尼大学（悉尼）
            昆士兰大学（布里斯本） 墨尔本大学（墨尔本） 阿德莱德大学（阿德莱德） 澳大利亚大学学费一般在20000澳币一年，名牌大学在50000澳币一年。</p>
          <h4>澳洲就业优势分析</h4>
          <p>
            澳洲的人均收入全世界排名第六，而且，澳洲是一个福利国家，福利待遇非常好，在澳洲有许多热门的专业，比如，社会工作者，这些跟中国的志愿者类似，在移民清单里面，这个也是移民专业，薪资方面也是非常可观的。教师，在澳洲，教育专业分为两种，一个是teaching，一个是education，在移民清单里面，幼儿教育也是可以移民的，而且在当地的需求量比较大。要考虑的是，需求量大不意味着门槛低，澳洲是一个非常注重教育的国家，一般留学生申请都要雅思七分，而且单项不低于七。</p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-5.webp"/>
          <p>
            医学，跟国内一样，医护人员是澳洲相当受到尊重和欢迎的职业，悉尼和新南的医科专业都是非常不错的，要考虑的是，医学类专业，一般来说时间相对比其他专业长，学费也比较高，不过，收入是相当可观的，而且就业的选择也比较多。</p>
          <p>会计，澳洲的八大都有商科，以会计为代表，在澳洲是就业率非常高的职业。工程，上文提到，工程是移民专业，澳洲许多大学的。IT，跟IT有关的职业有IT工程师，程序员，这对学生的软件编程能力，判断能力非常高。</p>
          <p>建筑，对于建筑比较感兴趣并且考虑移民的，一下建筑专业，但是，一般澳洲的研究生专业，都是需要有一定得本科建筑专业背景。</p>
          <p>精算，精算是一个要求非常高的行业，在统计学，数学上感兴趣并且逻辑思维非常好的学生，可以考虑这个行业，并且，在商科里面，除了会计以外，这个是一个非常受欢迎的移民专业。</p>
          <p>心理学，心理学在国内发展的不是特别快，可是在澳洲，跟教师一样，是相当受尊重的行业，但是对于语言的要求和难度也比较高。</p>
          <h4>澳大利亚三大省就业情况</h4>
          <h5>新南威尔士州</h5>
          <p>
            矿藏、资源及动力业：发电及配电职业从业者招聘需求增加182%<br>
            稳妥及养老金业：司理招聘需求增加113%<br>
            建造业：工业设备及机械操作人员招聘需求增加97%<br>
            稳妥及养老金业：精算师招聘需求增加91%<br>
          </p>
          <h5>维多利亚州</h5>
          <p>
            制作及服务业：蛋糕烘培师傅招聘需求增加111%<br>
            矿藏、资源及动力业：安康安全及环境咨询师招聘需求增加109%<br>
            科技产业：食物技能及安全咨询师招聘需求增加95%<br>
            教诲及训练业：学生服务人员招聘需求增加80%<br>
            制作及服务业：印刷及出书业者招聘需求增加69%<br>
          </p>
          <h5>昆士兰州</h5>
          <p>
            银行及金融业：分析师招聘需求增加100%<br>
            医疗及旅游业：航空公司服务人员招聘需求增加80%<br>
            制作及运输业：司理招聘需求增加69%<br>
            零售及销售业：收购人员招聘需求增<br>
          </p>
        </div>
      </template>
      <template v-if="index === 5">
        <ion-item style="--border-width: 0;border: 0" class="ion-label" lines="none">
          <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-9.png" slot="start"/>
          <ion-label>时差概况</ion-label>
        </ion-item>
        <div class="ion-padding content">
          <p>澳洲一共有三个时区：西部时区、中部时区和东部时区。</p>
          <p>西部时区与中国是没有时差的，中部时区与中国时差1.5小时，东部时区与中国时差2小时。</p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-6.webp"/>
          <p>但是中部与东部的新州都有夏时制，所以夏时制时西澳州与中国有1小时时差，南澳州和北澳特区与中国有2.5小时时差，塔州，维州、新州、首都特区与中国有3小时时差。</p>
        </div>
      </template>
      <template v-if="index === 6">
        <ion-item style="--border-width: 0;border: 0" class="ion-label" lines="none">
          <img class="icon-img" src="https://static.yuzhangmen.cn/custom/aboutau/about-au-icon-7.png" slot="start"/>
          <ion-label>经济发展</ion-label>
        </ion-item>
        <div class="ion-padding content">
          <h5>金融货币</h5>
          <p>
            悉尼是澳大利亚第一大的城市，中央商务区也是全澳大利亚的金融、股市的商业重镇。<br>
            澳大利亚的货币是澳币，但最初是随英国采用英镑。后来发展自己的币制──澳大利亚镑，币值同于英镑。当时一镑有20先令；1先令有12便士。因此每镑有240便士。1966年，澳大利亚推行十进位制，亦同时改革货币。当时已是十进位制的1美元约价值0.5英镑，新的澳币改为与美元作等值连结。
          </p>
          <img src="https://static.yuzhangmen.cn/custom/aboutau/image-7.jpeg"/>
          <p>
            2010年，澳币正式取代瑞士法郎的国际地位，成为全球第五大流通货币。澳大利亚金融业极为发达，澳大利亚是世界上银行总价值第三高的国家，仅次于中国和美国。澳大利亚四大银行的市值甚至超过了欧元区所有国家所有银行的总和。</p>
          <p>澳大利亚四大金融集团是西太平洋银行、澳大利亚国民银行、澳新银行和联邦银行。</p>
          <h5>旅游</h5>
          <p>
            作为第三产业的重要组成部分，旅游业在澳大利亚得到长足而迅速的发展。因为有着优美的自然风光和珍稀动植物资源，加之发达的经济，澳大利亚成为让人流连忘返的旅游圣地。主要旅游景点有悉尼歌剧院、悉尼海港大桥、悉尼塔（南半球第二高建筑）、黄金海岸、大堡礁、北艾尔湖、墨尔本艺术馆、原住民发祥地卡卡杜国家公园及原住民文化区威兰吉湖区等。</p>
          <h5>对外贸易</h5>
          <p>
            澳大利亚为贸易强国，是世界十大农产品出口国和六大矿产资源出口国之一。小麦出口量高居在于世界第二位。主要出口商品有煤、黄金、铁矿石、原油、天然气、铝矾土、牛肉、羊毛、小麦、糖、饮料等。主要进口商品有航空器材、药物、通讯器材、车辆、原油、精炼油和汽车配件等。澳大利亚的最主要贸易对象有中国大陆、日本、台湾、美国、新西兰、香港、德国、英国、南非、沙特阿拉伯、印度、韩国、新加坡、印度尼西亚、巴西等。其中，日本、美国、新西兰、中国及新加坡为澳大利亚最重要的贸易伙伴。</p>
          <h5>科学技术</h5>
          <p>
            澳大利亚的科学技术在能源利用领域、医学及化学领域、市场资源管理、农业及畜牧业领域等贡献良多。在医药领域，一些大学以及众多学者对于人类器官移植基础以及免疫学，人类大脑神经，利用细菌以及微生物学方面为全球最为先进的国家之一，其中霍华德·弗洛里男爵曾与1945年因为与德国和英国的科学家共同研究出盘尼西林（青霉素），而获得诺贝尔医学奖。</p>
        </div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonContent, IonPage} from '@ionic/vue';
import {useRoute} from "vue-router";
import { chevronDownOutline } from 'ionicons/icons';

export default {
  name: "AboutAuDetail",
  components: {
    IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonContent, IonPage
  },
  data() {
    return {
      route: useRoute(),
      index: 1
    }
  },
  setup() {
    return { chevronDownOutline }
  },
  mounted() {
    this.index = ~~this.route.params.index || 1
  }
}
</script>

<style scoped>
ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
  color: #A7B0C2;
  font-size: 20px;
}
ion-col {
  --ion-grid-column-padding: 0;
}
.ion-label {
  font-size: 18px;font-weight: bold
}

h4, h5 {
  color: #1a1a1a;
}

.icon-img {
  width: 30px;
  margin-right: 10px;
}

.content {
  color: #6C6E73;
  line-height: 24px;
}
</style>